import { API_AXIOS } from "../http/interceptor";
import { showError, showSuccess } from "../utils/toastHelpers";

export const addLead = async ({ sendData }) => {
    try {
        const { data } = await API_AXIOS.post('lead/addLeadByForm', sendData)
        showSuccess(data?.message)
        return data;
    }
    catch (error) {
        console.log('error', error)
        showError(error?.response?.data?.message);
        throw new Error(error);
    }
}

export const addLeadChangeStatus = async ({ sendData }) => {
    try {
        const { data } = await API_AXIOS.post('lead/changeStatus', sendData)
        showSuccess(data?.message)
        return data;
    }
    catch (error) {
        console.log('error', error)
        showError(error?.response?.data?.message);
        throw new Error(error);
    }
}

export const addLeadAssignAgents = async ({ sendData }) => {
    try {
        const { data } = await API_AXIOS.post('lead/assignAgents', sendData)
        showSuccess(data?.message)
        return data;
    }
    catch (error) {
        console.log('error', error)
        showError(error?.response?.data?.message);
        throw new Error(error);
    }
}


export const leadAddNote = async ({ sendData }) => {
    try {
        const { data } = await API_AXIOS.post('lead/addNote', sendData)
        showSuccess(data?.message)
        return data;
    }
    catch (error) {
        console.log('error', error)
        showError(error?.response?.data?.message);
        throw new Error(error);
    }
}

export const addleadBySocialMedia = async ({ sendData }) => {
    try {
        const { data } = await API_AXIOS.post('lead/addLeadFromSocialMedia', sendData);
        showSuccess(data?.message);
        return data;
    }
    catch (error) {
        console.log(error);
        showError(error?.response?.data?.message);
        throw new Error(error);
    }
};