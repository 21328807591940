import {
  Box,
  HStack,
  Image,
  useBreakpoint,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { CustomText } from "./CustomText";
import { font } from "../consts/font";
import { color } from "../consts/color";
import { useNavigate } from "react-router-dom";
import { svg } from "../assets/svg.js";
import Filters from "../components/Filters.jsx";

const MyContainer = ({
  children,
  header,
  subHeader,
  subHeaderTitle,
  subHeaderTitleList,
  btnComponent,
  childContainer,
  childContainer1,
  isBack,
  arrowContain,
  arrowContains,
  isheaderWhite,
}) => {
  const navigate = useNavigate();
  const isPhoneView = useBreakpointValue({ base: true, md: false });

  const handleNavigate = (e) => {
    const navigate_URI = e.target.baseURI;
    if (navigate_URI.includes("/meeting-details")) {
      navigate("/meetings");
    } else {
      navigate(-1);
    }
  };
  const hasFilters = React.Children.toArray(btnComponent).some(child =>
    React.isValidElement(child) && child.type === Filters
  );

  const shouldNavigateSubHeader = !!subHeaderTitle;
  const shouldNavigateSubHeaderTitle =
    !!subHeader && !!subHeaderTitle && !!subHeaderTitleList;

  return (
    <Box
      width="100%"
      height="100vh" // Ensures the outer Box takes full viewport height
      display="flex"
      flexDirection="column"
      bgColor={{ base: "transparent" }}
    >
      <Box
        width="100%"
        flex="0 1 auto"
        display={{ base: "block", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        padding={{ base: "0px 15px 10px 15px", md: "10px 25px" }}
        bgColor={isheaderWhite ? "white" : null}
        position={{ base: "fixed", md: "inherit" }}
        overflow={{ base: "hidden", md: "inherit" }}
        zIndex={"1"}
        minWidth={{ base: "0px", md: "900px" }} // Prevent overflow in the header
      >
        <HStack justifyContent={"flex-start"} gap={"20px"}>
          {isBack && (
            <Image
              src={svg.backIcon}
              alt="Back Icon"
              objectFit="contain"
              onClick={handleNavigate}
              height={"25px"}
              alignSelf={"baseline"}
              mt={"12px"}
            />
          )}
          <VStack alignItems="flex-start" width="100%">
            <CustomText
              fontFamily={font.Oswald}
              fontSize={{base:'22px',md:'30px'}}
              fontWeight="500"
            >
              {header || "N/A"}
            </CustomText>
            <Box display={"flex"}>
              <CustomText
                fontFamily={font.Oswald}
                fontSize={{base:'14px',md:'16px'}}
                fontWeight="400"
                textAlign="left"
                textDecoration={shouldNavigateSubHeader ? "underline" : "none"}
                color={color.secondaryBtn}
                gap={"0rem"}
                onClick={shouldNavigateSubHeader ? handleNavigate : undefined}
                cursor={shouldNavigateSubHeader ? "pointer" : "default"}
              >
                {subHeader}
              </CustomText>
              {arrowContain && (
                <Image
                  src={svg.arrowContain}
                  alt="Arrow Icon"
                  objectFit="contain"
                  height={"10px"}
                  padding={"0px 6px"}
                  marginTop={"10px"}
                />
              )}
              <CustomText
                fontFamily={font.Oswald}
                fontSize={{base:'14px',md:'16px'}}
                fontWeight="400"
                textAlign="left"
                textDecoration={
                  shouldNavigateSubHeaderTitle ? "underline" : "none"
                }
                color={
                  shouldNavigateSubHeaderTitle ? color.secondaryBtn : "#9A4D49"
                }
                gap={"0rem"}
                onClick={
                  shouldNavigateSubHeaderTitle ? handleNavigate : undefined
                }
                cursor={shouldNavigateSubHeaderTitle ? "pointer" : "default"}
              >
                {subHeaderTitle}
              </CustomText>
              {arrowContains && (
                <Image
                  src={svg.arrowContain}
                  alt="Arrow Icon"
                  objectFit="contain"
                  height={"10px"}
                  padding={"0px 6px"}
                  marginTop={"10px"}
                />
              )}
              <CustomText
                fontFamily={font.Oswald}
                fontSize={{base:'14px',md:'16px'}}
                fontWeight="400"
                textAlign="left"
                color={color.secondaryBtn}
                gap={"0rem"}
                cursor="default"
              >
                {subHeaderTitleList}
              </CustomText>
            </Box>
          </VStack>
        </HStack>
        <Box
          display="flex"
          gap={{ base: "6px", md: "20px" }}
          alignItems={"center"}
          marginTop={hasFilters ? { base: "-0.7rem", md: "inherit" } : { base: "0rem", md: "inherit" }} // Apply margin if Filters is present
          marginBottom={hasFilters ? { base: "-0.7rem", md: "inherit" } : { base: "0rem", md: "inherit" }} // Apply margin if Filters is present

        >
          {btnComponent}
        </Box>
      </Box>

      <Box
        width="100%"
        flex="1 1 auto" // Allows this Box to grow and take available space
        marginTop={{ base: "6rem", md: "0rem" }} // Make sure this is enough to clear the fixed header
        overflowY="auto" // Allow vertical scrolling for content
        minWidth={"900px"}
        style={{ ...childContainer1 }}
      >
        <Box
          style={{
            marginLeft: "10px",
            marginRight: "50px",
            paddingTop: "10px",
            ...childContainer,
            // marginTop: isPhoneView ? "5rem" : "0rem",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(MyContainer);
