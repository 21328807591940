import { Box, Text } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import { CustomText } from '../../myComponent/CustomText'

export default function DropDown({
    placeholder,
    containerStyle,
    width,
    label,
    options,
    isLoading,
    onInputChange,
    onMenuScrollToBottom,
    onChange,
    value,
    getOptionValue,
    getOptionLabel,
    isMulti = false,
    error,
    isDisabled = false,
    props,
    noOfLines = 2,
}) {
    return (
        <Box
            width={width || '100%'}
            style={containerStyle}
        >
            {label &&
                <Text
                    marginBottom={2}
                    fontWeight="500"
                    fontSize={16}
                >{label}</Text>}
            <Select
                placeholder={placeholder || label || "Search and select..."}
                options={options}
                isLoading={isLoading}
                value={value}
                onInputChange={onInputChange}
                onMenuScrollToBottom={onMenuScrollToBottom}
                isMulti={isMulti}
                onChange={onChange}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                useBasicStyles={true}
                noOfLines = {noOfLines}
                chakraStyles={{
                    menuList: (provided) => ({
                        ...provided,
                        minHeight: '200px',
                    }),
                    container: (provided) => ({
                        ...provided,
                        // ...provided.container,
                        bgColor: 'rgba(249, 249, 249, 1)',
                        borderRadius: '7px',
                        border: '1px solid #ccc',
                    }),
                    control: (provided) => ({
                        ...provided,
                        boxShadow: 'none',
                        borderColor: 'transparent',
                        _focus: {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                        },
                        _hover: {
                            borderColor: 'transparent',
                        },
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        color: 'gray',
                        padding: '0px',
                        'svg': {
                            width: '20px', // Adjust the thickness by changing the width and height
                            height: '20px',
                        },
                    }),
                    clearIndicator: (provided) => ({
                        ...provided,
                        color: 'gray',
                        padding: '0px',
                        'svg': {
                            width: '10px', // Adjust the thickness by changing the width and height
                            height: '10px',
                        },
                    }),
                }}
                isDisabled={isDisabled}
                {...props}
            />
            {error && (
                <CustomText color='red' fontSize="12px">
                    {error}
                </CustomText>
            )}
        </Box>
    )
}


// import { Box, Text, Spinner } from '@chakra-ui/react';
// import { Select } from 'chakra-react-select';
// import React from 'react';
// import { CustomText } from '../../myComponent/CustomText';

// export default function DropDown({
//     placeholder,
//     containerStyle,
//     width,
//     label,
//     options,
//     isLoading,
//     onInputChange,
//     onMenuScrollToBottom,
//     onChange,
//     value,
//     getOptionValue,
//     getOptionLabel,
//     isMulti = false,
//     error,
//     isDisabled = false,
//     props
// }) {
//     return (
//         <Box
//             width={width || '100%'}
//             style={containerStyle}
//         >
//             {label &&
//                 <Text
//                     marginBottom={2}
//                     fontWeight="500"
//                     fontSize={16}
//                 >{label}</Text>}
//             <Select
//                 placeholder={placeholder || label || "Search and select..."}
//                 options={options}
//                 isLoading={false}  // Disable default loading spinner provided by `chakra-react-select`
//                 value={value}
//                 onInputChange={onInputChange}
//                 onMenuScrollToBottom={onMenuScrollToBottom}
//                 isMulti={isMulti}
//                 onChange={onChange}
//                 getOptionLabel={getOptionLabel}
//                 getOptionValue={getOptionValue}
//                 useBasicStyles={true}
//                 chakraStyles={{
//                     menuList: (provided) => ({
//                         ...provided,
//                         minHeight: '200px',
//                         padding: '0px',
//                         position: 'relative',
//                     }),
//                     container: (provided) => ({
//                         ...provided,
//                         bgColor: 'rgba(249, 249, 249, 1)',
//                         borderRadius: '7px',
//                         border: '1px solid #ccc',
//                     }),
//                     control: (provided) => ({
//                         ...provided,
//                         boxShadow: 'none',
//                         borderColor: 'transparent',
//                         _focus: {
//                             borderColor: 'transparent',
//                             boxShadow: 'none',
//                         },
//                         _hover: {
//                             borderColor: 'transparent',
//                         },
//                     }),
//                     dropdownIndicator: (provided) => ({
//                         ...provided,
//                         color: 'gray',
//                         padding: '0px',
//                         'svg': {
//                             width: '20px',
//                             height: '20px',
//                         },
//                     }),
//                     clearIndicator: (provided) => ({
//                         ...provided,
//                         color: 'gray',
//                         padding: '0px',
//                         'svg': {
//                             width: '10px',
//                             height: '10px',
//                         },
//                     }),
//                 }}
//                 isDisabled={isDisabled}
//                 {...props}
//             />
//             {/* {isLoading && (
//                 <Box
//                     position="absolute"
//                     top="0"
//                     right="0"
//                     bottom="0"
//                     left="0"
//                     display="flex"
//                     alignItems="center"
//                     justifyContent="center"
//                     bg="rgba(255, 255, 255, 0.8)"
//                     zIndex="999"
//                 >
//                     <Spinner size="lg" />
//                 </Box>
//             )} */}
//             {error && (
//                 <CustomText color='red' fontSize="12px">
//                     {error}
//                 </CustomText>
//             )}
//         </Box>
//     );
// }
