import { API_AXIOS } from "../../../http/interceptor";

export const getLeads = async ({
    pageParam = 1,
    search = '',
    status
  }) => {
    try {
      const { data } = await API_AXIOS.get(`lead`, {
        params: {
          page: pageParam,
          search,
          status
        },
      });
      return data || {};
    } catch (error) {
  
      console.log(error);
    }
  };


  export const getLeadDetailsById = async (id) => {
    try {
      const res = await API_AXIOS.get(`lead/${id}`);
      return res.data;
    } catch (error) {
      console.error('Lead Details Error:', error);
      throw error;
    }
  };