import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getLeadDetailsById, getLeads } from "../useFunction/leadFunction";
// import { useQuery } from "@chakra-ui/react";

export const useLeadQuery = ({ search, status }) => {

  let res = useInfiniteQuery({
    queryKey: ["leads", status, search],
    queryFn: ({ pageParam = 1 }) => getLeads({ pageParam, search, status }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.pagination?.hasNext ? parseInt(lastPage?.pagination?.currentPage) + 1 : undefined;
    },
    staleTime: 1000 * 60 * 10,
  });
  let data = []
  if (res) {
    data = res?.data?.pages?.map(page => page?.data).flat();
  }
  return { ...res, data }
}


  export const useGetLeadById=(id)=>{
    let res = useQuery({
      queryKey:["leads", id],
      queryFn:()=>getLeadDetailsById(id),
      staleTime: 1000 * 60 * 10,
      enabled:!!id
    })
    return res
  }